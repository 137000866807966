
import { defineComponent, PropType } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { hideModal } from "@/core/helpers/dom";
import $ from "jquery";

export default defineComponent({
  name: "claim-remarks-modal-component",
  components: { ErrorMessage, Field, Form },
  props: {
    userType: Object as any,
    adminUser: String,
    brokerUser: String,
    clientUser: String,
    route: String,
    status: String,
    remarks: Array as PropType<Record<string, any>[]>,
  },
  data() {
    return {
      claimRemarks: [{ remarks: "" }],
      displayRemarkForm: false,
    };
  },
  setup() {
    const remarksValidator = Yup.object().shape({
      claimRemarks: Yup.array()
        .of(
          Yup.object().shape({
            remarks: Yup.string()
              .required()
              .min(6)
              .label("Remark"),
          })
        )
        .strict(),
    });

    return {
      remarksValidator,
    };
  },
  methods: {
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addDynamicField() {
      this.claimRemarks.push({
        remarks: "",
      });
    },
    removeDynamicField(counter) {
      $(".remove-" + counter).remove();
    },
    submitRemarks(values, { resetForm }) {
      const submitButton2 = document.getElementById("kt_remarks_submit");

      if (submitButton2) {
        // Activate indicator
        submitButton2.setAttribute("data-kt-indicator", "on");
        const formData = new FormData();

        setTimeout(() => {
          submitButton2.removeAttribute("data-kt-indicator");

          for (const [, value] of Object.entries(values["remarks"])) {
            if (Array.isArray(value)) {
              value.forEach((itemValue, index) => {
                formData.append(`remarks[${index}]`, itemValue);
              });
            }
          }

          // Update claim number
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(`${this.route}/remarks`, values)
              .then(() => {
                variables.toastAlert(
                  "Claim remarks created successfully",
                  "success"
                );
                resetForm();
                hideModal(document.getElementById("claim_remarks_modal"));
                this.$emit("reloadClaim");
              })
              .catch(function() {
                // variables.toastAlert(error.response.data.error, "error");
                variables.toastAlert("Remark field cannot be empty", "error");
              });
          }
        }, 2000);
      }
    },
  },
});
