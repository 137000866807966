
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "third-part-form-component",
  components: { Field, ErrorMessage, ImagesUploader },
  props: {
    thirdParties: Array,
  },
  data() {
    return {
      isAtFault: { field: false, class: "col-xl-12" },
      displayBodilyInjury: false,
      displayDeath: false,
      displayThirdPartyVehicle: false,
      displayThirdPartyProperty: false,
      displayThirdPartyBodily: false,
      vehicleBrands: [{} as any],
      vehicleModels: {} as any,
      items: [
        {
          vehicleDamageFullName: "",
          vehicleDamagePhoneNumber: "",
          address: "",
          // vehicleDamageDriverLicense: "",
          // vehicleDamageInsuranceCertificate: "",
          thirdPartyIncidentType: "",
          vehicleDamageAmount: "",
          vehicleDamageAmountImage: "",
          vehicleDamageImages: "",
          vehicleDamageVehicleBrand: "",
          vehicleDamageVehicleModel: "",
          vehicleDamageRegistrationNumber: "",
          vehicleDamageDescription: "",
          propertyDamageImages: "",
          propertyDamageReceipt: "",
          propertyDamageAmount: "",
          propertyDamageAmountImage: "",
          propertyDamageAddress: "",
          propertyDamageDescription: "",
          propertyDamageFullName: "",
          propertyDamagePhoneNumber: "",
          bodilyInjurySelection: "",
          bodilyInjuryMedicalReport: "",
          bodilyInjuryImages: "",
          bodilyInjuryMedicalBill: "",
          bodilyInjuryAddress: "",
          bodilyInjuryPhoneNumber: "",
          bodilyInjuryFullName: "",
          deathFullName: "",
          deathPhoneNumber: "",
          deathAddress: "",
          deathPhotographEvidence: "",
          deathCertificate: "",
          deathLossClaim: "",
          deathMOI: "",
          thirdPartyIncidentVehicle: false,
          thirdPartyIncidentProperty: false,
          thirdPartyIncidentBodily: false,
          displayBodilyInjury: false,
          displayDeath: false,
          displayApprehensionField: false,
        },
      ],
    };
  },
  setup() {
    const { isEmptyArray } = ResusableFunctions();

    return {
      isEmptyArray,
    };
  },
  created() {
    if (
      this.thirdParties !== undefined &&
      !this.isEmptyArray(this.thirdParties)
    ) {
      this.items = [...this.thirdParties] as any;
    }

    this.getVehicles();
  },
  methods: {
    addCommaToNumber(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // return variables.addCommaToNumber(amount);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    addDynamicField() {
      this.items.push({
        vehicleDamageFullName: "",
        vehicleDamagePhoneNumber: "",
        address: "",
        thirdPartyIncidentType: "",
        // vehicleDamageDriverLicense: "",
        // vehicleDamageInsuranceCertificate: "",
        vehicleDamageAmountImage: "",
        vehicleDamageAmount: "",
        vehicleDamageImages: "",
        vehicleDamageVehicleBrand: "",
        vehicleDamageVehicleModel: "",
        vehicleDamageRegistrationNumber: "",
        vehicleDamageDescription: "",
        propertyDamageImages: "",
        propertyDamageReceipt: "",
        propertyDamageAmount: "",
        propertyDamageAmountImage: "",
        propertyDamageAddress: "",
        propertyDamageDescription: "",
        propertyDamageFullName: "",
        propertyDamagePhoneNumber: "",
        bodilyInjurySelection: "",
        bodilyInjuryMedicalReport: "",
        bodilyInjuryImages: "",
        bodilyInjuryMedicalBill: "",
        bodilyInjuryAddress: "",
        bodilyInjuryPhoneNumber: "",
        bodilyInjuryFullName: "",
        deathFullName: "",
        deathPhoneNumber: "",
        deathAddress: "",
        deathPhotographEvidence: "",
        deathCertificate: "",
        deathLossClaim: "",
        deathMOI: "",
        thirdPartyIncidentVehicle: false,
        thirdPartyIncidentProperty: false,
        thirdPartyIncidentBodily: false,
        displayBodilyInjury: false,
        displayDeath: false,
        displayApprehensionField: false,
      });
    },
    removeDynamicField(counter) {
      $(".remove-form-" + counter).remove();
    },
    bodilySelection(type, counter) {
      const item = this.items[counter];
      if (type === "bodilyInjury") {
        item.displayBodilyInjury = !item.displayBodilyInjury;
        if (item.displayBodilyInjury) {
          item.displayDeath = false;
        }
      } else if (type === "death") {
        item.displayDeath = !item.displayDeath;
        if (item.displayDeath) {
          item.displayBodilyInjury = false;
        }
      }
    },
    areYouAtFaultSelection(event, counter) {
      const item = this.items[counter];

      const field =
        event.target.options[event.target.selectedIndex].value == "Yes"
          ? true
          : false;

      if (field) {
        item.displayApprehensionField = true;
      } else {
        item.displayApprehensionField = false;
      }
    },
    removeSpecialCharacters(event) {
      if (variables.containsSpecialChars(event.target.value)) {
        const newValue = event.target.value
          .replace(/\D/g, "")
          .replace(`\`!@#$%^&*()_+\\=\\[\\]{};':"\\|,.<>\\/?~`, ",");

        setTimeout(() => {
          this.$nextTick(() => (event.target.value = newValue));
        }, 0.1);
      }
    },
    getVehicles() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CARS_ROUTE}/cars`)
          .then(({ data }) => {
            this.vehicleBrands = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getVehicleModels(event, counter) {
      // this.vehicleModels =
      //   event.target.options[event.target.selectedIndex].dataset.models;

      // if (this.vehicleModels) {
      //   this.vehicleModels[counter] = JSON.parse(this.vehicleModels.toString());
      // } else {
      //   this.vehicleModels[counter] = [];
      // }

      const selectedBrand = event.target.value;
      const selectedVehicleBrand = this.vehicleBrands.find(
        (brand) => brand.name === selectedBrand
      );

      if (selectedVehicleBrand) {
        // Directly assign to vehicleModels for the specific counter
        this.vehicleModels[counter] = selectedVehicleBrand.models || [];
      } else {
        // Clear models if no valid brand is selected
        this.vehicleModels[counter] = [];
      }
    },
    capitalize(value) {
      return variables.capitalize(value);
    },
  },
});
