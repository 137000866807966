
import { defineComponent, ref } from "vue";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "claim-upload-discharge-voucher-modal-component",
  components: { ImagesUploader },
  props: {
    details: Object,
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);

    const addCommaToNumberString = (amount: any) => {
      return variables.addCommaToNumberString(amount);
    };

    const submit = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          const form = document.forms[0];
          const formData = new FormData(form);

          // Send personal information update request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(
              `${variables.CLAIMS_ROUTE}/${props?.details?.publicId}/discharge-voucher`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                hideModal(document.getElementById("discharge_voucher_modal"));
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    return { submit, addCommaToNumberString, submitButton1 };
  },
});
