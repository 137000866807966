
import { defineComponent, PropType, ref } from "vue";
import { useStore } from "vuex";
import variables from "@/router/api";
import ClaimUploadDischargeVoucher from "@/components/modals/ClaimUploadDischargeVoucher.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "client-claims-table",
  components: { ClaimUploadDischargeVoucher },
  props: {
    widgetClasses: String,
    claims: Array as PropType<Record<string, any>[]>,
    type: String,
  },
  data() {
    return {
      link: "",
      role: "",
      underwriter: variables.UNDERWRITER_USER_ROLE,
    };
  },
  setup(props) {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();
    const details = ref({});

    const setUploadDVDetails = (item: any) => {
      details.value = item;
    };

    if (props.claims && props.claims.length > 0) {
      displayDatatable(["basicExample"]);
    }

    return {
      useReusableNavigation,
      reinitialization,
      setUploadDVDetails,
      details,
    };
  },
  created() {
    const store = useStore();
    this.role = store.getters.currentUser.role;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
