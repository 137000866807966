
import { defineComponent } from "vue";
import variables from "@/router/api";

export default defineComponent({
  name: "claim-discharge-voucher-modal-component",
  components: {},
  props: {
    claim: Object as any,
  },
  setup() {
    const addCommaToNumberString = (amount: any) => {
      return variables.addCommaToNumberString(amount);
    };
    return { addCommaToNumberString };
  },
});
