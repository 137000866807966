
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "comprehensive-third-part-form-component",
  components: { Field, ErrorMessage },
  data() {
    return {
      vehicleBrands: [{} as any],
      vehicleModels: [{} as any],
      thirdPartyDriverLicense: "",
      thirdPartyInsuranceCertificate: "",
      thirdPartyAddress: "",
      thirdPartyFullName: "",
      thirdPartyPhoneNumber: "",
      thirdPartyDamageDescription: "",
      thirdPartyVehicleModel: "",
      thirdPartyVehicleBrand: "",
      thirdPartyRegistrationNumber: "",
    };
  },
  setup() {
    const { isEmptyArray } = ResusableFunctions();

    return {
      isEmptyArray,
    };
  },
  created() {
    this.getVehicles();
  },
  methods: {
    addCommaToNumber(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // return variables.addCommaToNumber(amount);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    getVehicles() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CARS_ROUTE}/cars`)
          .then(({ data }) => {
            this.vehicleBrands = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getVehicleModels(event) {
      this.vehicleModels =
        event.target.options[event.target.selectedIndex].dataset.models;

      if (this.vehicleModels) {
        this.vehicleModels = JSON.parse(this.vehicleModels.toString());
      }
    },
    capitalize(value) {
      return variables.capitalize(value);
    },
    removeSpecialCharacters(event) {
      if (variables.containsSpecialChars(event.target.value)) {
        const newValue = event.target.value
          .replace(/\D/g, "")
          .replace(`\`!@#$%^&*()_+\\=\\[\\]{};':"\\|,.<>\\/?~`, ",");

        setTimeout(() => {
          this.$nextTick(() => (event.target.value = newValue));
        }, 0.1);
      }
    },
  },
});
