import { ref } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import "jquery/dist/jquery.min.js";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

export default function ReusableFunctions() {
  const randomNumber = ref(0);
  const slug = ref("");
  const selectedFile = ref("null");
  const router = useRouter();

  const isObjectEmpty = (obj: object): boolean => {
    // Check if obj is null or undefined
    if (obj === null || obj === undefined) {
      return true;
    }

    // Check if obj is not empty
    if (Object.keys(obj).length > 0) {
      for (const key in obj) {
        if (!obj[key]) {
          return false;
        }
      }
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    } else {
      return false; // Return false if obj is empty
    }
  };

  const isObjectTrulyEmpty = (obj) => {
    if (obj === null || obj === undefined) {
      return true;
    }

    return Object.values(obj).every(
      (arr) => Array.isArray(arr) && arr.length === 0
    );
  };

  const isEmptyArray = (array): boolean => {
    return (
      array === null ||
      array === undefined ||
      (Array.isArray(array) && array.length === 0)
    );
  };

  const countArrayItems = (array): number => {
    if (isEmptyArray(array)) {
      return 0;
    }

    return array.length;
  };

  const generateRandomNumber = () => {
    randomNumber.value = Math.floor(Math.random() * 2);
  };

  // Function to reinitialize the component
  const reinitialization = () => {
    MenuComponent.reinitialization();
  };

  // Function to truncate text
  const truncate = (string: string) => {
    return variables.truncate(string, 150);
  };

  // Function to handle file input change
  const handleFileChange = (event: any) => {
    selectedFile.value = event.target.files[0];
  };

  const isDateBeforeToday = (compareDate: any) => {
    const today = new Date();
    const compare = new Date(compareDate);

    // We compare only the dates, not the time
    today.setHours(0, 0, 0, 0);
    compare.setHours(0, 0, 0, 0);

    return today > compare;
  };

  const currentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();

    // return mm + "/" + dd + "/" + yyyy;
    // return mm + "-" + dd + "-" + yyyy;
    return yyyy + "-" + mm + "-" + dd;
  };

  const useReusableNavigation = (
    isUrl: boolean,
    route: string,
    props: any = null
  ) => {
    // Navigate using a URL
    if (isUrl) {
      return router.push(route);
    }
    // Navigate using a named route
    return router.push({ name: route, params: props });
  };

  // Function to transform the first letter of a word to uppercase
  const capitalizeFirstLetterOnly = (word: string) => {
    // Check if the word is not empty
    if (word != undefined && word.length > 0) {
      // Transform the first letter to uppercase and concatenate the rest of the word
      return word.charAt(0).toUpperCase() + word.slice(1);
    }

    // Return an empty string if the word is empty
    return "";
  };

  const capitalizeFirstLetter = (str: string) => {
    // Convert the first character to uppercase and concatenate with the rest of the string in lowercase
    if (str != undefined && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    // Return an empty string if the word is empty
    return "";
  };

  const capitalize = (value: string) => {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const capitalizeWords = (word: string): string => {
    return word.replace(/\b\w/g, (match) => match.toUpperCase());
  };

  // Function tthat converts a string to a slug
  const convertToSlug = (text: string): string => {
    if (!text.trim()) {
      return "";
    }

    return text
      .toLowerCase()
      .replace(/[^\w\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/--+/g, "-")
      .trim();
  };

  const getSlug = (text: string): string => {
    return (slug.value = convertToSlug(text));
  };

  const updateApiHeader = (isJson = true): object => {
    return {
      headers: {
        "Content-Type": isJson ? "application/json" : "multipart/form-data",
        _method: "PUT",
        "X-HTTP-Method-Override": "PUT",
      },
    };
  };

  const createApiHeader = (isJson = true): object => {
    return {
      headers: {
        "Content-Type": isJson ? "application/json" : "multipart/form-data",
        _method: "POST",
      },
    };
  };

  const patchApiHeader = (isJson = true): object => {
    return {
      headers: {
        "Content-Type": isJson ? "application/json" : "multipart/form-data",
        _method: "PATCH",
        "X-HTTP-Method-Override": "PATCH",
      },
    };
  };

  const getDaySuffix = (day: number) => {
    if (day >= 11 && day <= 13) {
      return "th";
    }

    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const humanReadableDate = (dateOfBirth: string) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const dob = new Date(dateOfBirth);
    const day = dob.getDate();
    const month = months[dob.getMonth()];
    const year = dob.getFullYear();
    const dayOfWeek = daysOfWeek[dob.getDay()];

    const suffix = getDaySuffix(day);

    return `${dayOfWeek} ${day}${suffix} ${month} ${year}`;
  };

  const displayDatatable = (ids: any) => {
    setTimeout(() => {
      ids.forEach((id: string) => {
        $(`#${id}`).DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            zeroRecords: "No matching records found",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          processing: true,
          destroy: true,
        });
      });
    }, 1000);
  };

  const getDynamicModelName = (
    index: any,
    arrayName: string,
    arrayProperty: string
  ) => {
    return `${arrayName}[${index}][${arrayProperty}]`;
  };

  const removeDashesFromUUID = (uuid: string): string => {
    // Replace dashes in the UUID string with an empty string
    return uuid.replace(/-/g, "");
  };

  const generateUUID = (): string => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const stripExtraDots = (value) => {
    const dotCount = value.split(".").length - 1;

    if (dotCount >= 2) {
      const parts = value.split(".");
      parts.pop(); // Remove the last part after the last dot
      return parts.join(".");
    }

    return value;
  };

  const formatNumber = (value) => {
    let numericValue = value.replace(/[^0-9.-]/g, "").replace(/^0+/, "");

    // Strip extra decimal separators
    numericValue = stripExtraDots(numericValue);

    // Separate integer and decimal parts
    const [integerPart, decimalPart] = numericValue.split(".");

    // Format the integer part to thousands
    const formattedIntegerPart = integerPart.replace(/\d(?=(\d{3})+$)/g, "$&,");

    // If decimal part is present, limit it to two places; otherwise, append .00
    const formattedDecimalPart = decimalPart
      ? `.${decimalPart.slice(0, 2)}`
      : ".00";

    // Combine the formatted integer and decimal parts
    const formattedValue = `${formattedIntegerPart}${formattedDecimalPart}`;

    return formattedValue;
  };

  const unformatNumber = (value): any => {
    console.log(value.length > 0);
    if (value.length > 0) {
      return parseFloat(value.replace(/,/g, ""));
    }
  };

  const acceptNumberOnly = (event) => {
    const keyCode = event.keyCode;

    // Allow only numeric characters (0-9), backspace, delete, left arrow, and right arrow
    if (!(keyCode >= 48 && keyCode <= 57)) {
      return event.preventDefault();
    }

    if (![8, 46, 37, 39].includes(keyCode)) {
      return event.preventDefault();
    }
  };

  const removeLastHyphen = (str: string): string => {
    // Find the last occurrence of hyphen
    const lastHyphenIndex = str.lastIndexOf("-");

    // Check if a hyphen exists in the string
    if (lastHyphenIndex !== -1) {
      // Remove the last hyphen using substring
      return (
        str.substring(0, lastHyphenIndex) + str.substring(lastHyphenIndex + 1)
      );
    }

    // If no hyphen exists, return the original string
    return str;
  };

  const formatDateToDayMonthYear = (inputDate: string): string => {
    // Ensure inputDate is a Date object
    const date = new Date(inputDate);

    // Extract date components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero based
    const year = date.getFullYear();

    // Return formatted date
    return `${day}/${month}/${year}`;
  };

  const removeEmptyFormDataKeys = (formData, keysToCheck) => {
    // Create a new FormData instance to hold the filtered data
    const newFormData = new FormData();

    // Convert keysToCheck to a Set for faster lookups
    const keysToCheckSet = new Set(keysToCheck);

    // Iterate over existing FormData entries
    for (const [key, value] of formData.entries()) {
      if (
        !keysToCheckSet.has(key) ||
        (keysToCheckSet.has(key) && value.trim() !== "")
      ) {
        newFormData.append(key, value);
      }
    }

    return newFormData;
  };

  const findObjectByKeyValue = (array, key, value) => {
    if (isEmptyArray(array)) {
      return null;
    }

    return array.find((item) => item[key] === value);
  };

  const keyExistsInObject = (key, obj) => {
    if (isObjectEmpty(obj)) {
      return false;
    }

    // Check if a key exists in an object
    return Object.prototype.hasOwnProperty.call(obj, key);
    // return obj.hasOwnProperty(key);
  };

  const getExactDateFromDate = (
    dateInput,
    yearDuration = 1,
    shortCode = ""
  ) => {
    // Convert the input to a Date object if it's a string or already a Date object
    const startDate = new Date(dateInput);

    // Check if the date is valid by using getTime()
    if (isNaN(startDate.getTime())) {
      throw new Error("Invalid date input");
    }

    const newDate = new Date(startDate); // Create a copy of the date
    newDate.setFullYear(startDate.getFullYear() + yearDuration); // Set the year based on the provided duration

    // Handle the edge case for February 29th (leap year)
    if (startDate.getDate() === 29 && startDate.getMonth() === 1) {
      // If the original date was February 29th, check if the new year is a leap year
      if (newDate.getMonth() !== 1) {
        newDate.setMonth(2); // Move to March 1st if the next year is not a leap year
        newDate.setDate(1);
      }
    }

    // If the shortCode is 'MOT' and the day is greater than 1, subtract one day
    if (shortCode === "MOT" && newDate.getDate() > 1) {
      newDate.setDate(newDate.getDate() - 1);
    }

    if (newDate.getDate() === 1) {
      // Subtract 1 day to get the last day of the previous month
      newDate.setMonth(newDate.getMonth() - 1);
      const lastDayPrevMonth = new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        0
      ); // Get the last day of the previous month
      newDate.setDate(lastDayPrevMonth.getDate());
    }

    // Format the date as yyyy-mm-dd
    const yyyy = newDate.getFullYear();
    let mm: string | number = newDate.getMonth() + 1; // Months are zero-indexed
    let dd: string | number = newDate.getDate();

    // Ensure two-digit formatting for month and day
    if (mm < 10) mm = "0" + mm;
    if (dd < 10) dd = "0" + dd;

    // Return the date as yyyy-mm-dd
    return `${yyyy}-${mm}-${dd}`;
  };

  const splitToWords = (str) => {
    if (typeof str !== "string") return "";

    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^([a-z])/, (match) => match.toUpperCase())
      .split(" ")
      .join(" ");
  };

  return {
    isObjectEmpty,
    isEmptyArray,
    countArrayItems,
    generateRandomNumber,
    reinitialization,
    truncate,
    randomNumber,
    handleFileChange,
    isDateBeforeToday,
    currentDate,
    useReusableNavigation,
    capitalizeFirstLetterOnly,
    capitalizeFirstLetter,
    capitalize,
    convertToSlug,
    slug,
    getSlug,
    updateApiHeader,
    createApiHeader,
    patchApiHeader,
    humanReadableDate,
    displayDatatable,
    getDynamicModelName,
    removeDashesFromUUID,
    generateUUID,
    capitalizeWords,
    formatNumber,
    unformatNumber,
    acceptNumberOnly,
    removeLastHyphen,
    formatDateToDayMonthYear,
    removeEmptyFormDataKeys,
    findObjectByKeyValue,
    isObjectTrulyEmpty,
    keyExistsInObject,
    getExactDateFromDate,
    splitToWords,
  };
}
