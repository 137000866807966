
import { defineComponent } from "vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import "viewerjs/dist/viewer.css";
import ClaimSettlementOffer from "@/components/modals/ClaimSettlementOffer.vue";
import ClaimNumberAssignment from "@/components/modals/ClaimNumberAssignment.vue";
import ClaimRemarks from "@/components/modals/ClaimRemarks.vue";
import ClaimDischargeVoucher from "@/components/modals/ClaimDischargeVoucher.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "claims-details-component",
  components: {
    GoBackButton,
    ClaimSettlementOffer,
    ClaimNumberAssignment,
    ClaimRemarks,
    ClaimDischargeVoucher,
  },
  props: {
    widgetClasses: String,
    publicId: String,
  },
  data() {
    return {
      hprClass: "col-xl-4",
      isAccidentClaim: false,
      isThirdPartyClaim: false,
      isFireClaim: false,
      isStolenClaim: false,
      isVandalisedClaim: false,
      displayRemarkForm: false,
      claim: {} as any,
      userType: {
        role: "",
      },
      claimRemarks: [{ remarks: "" }],
      clientUser: "",
      brokerUser: "",
      adminUser: "",
      underwriterUser: "",
      coopAdminUser: "",
      claimNumber: "",
      imageURL: "",
      deleteRoute: "",
      route: "",
    };
  },
  setup() {
    const { isEmptyArray, splitToWords } = ResusableFunctions();

    const downloadImage = (imageURL, publicId) => {
      const link = document.createElement("a");
      link.href = `${imageURL}${publicId}`;
      link.download = publicId;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return { downloadImage, isEmptyArray, splitToWords };
  },
  created() {
    const router = useRouter();
    const store = useStore();
    this.userType = store.getters.currentUser;
    this.clientUser = variables.INDIVIDUAL_CLIENT_USER_ROLE;
    this.brokerUser = variables.BROKER_USER_ROLE;
    this.adminUser = variables.SUPER_ADMIN_USER_ROLE;
    this.underwriterUser = variables.UNDERWRITER_USER_ROLE;
    this.coopAdminUser = variables.COOPERATIVE_ADMIN_USER_ROLE;

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }
    // Claim images route
    this.imageURL = variables.POLICY_IMAGES_ROUTE;

    // Get claim information route based on user roles
    if (this.userType.role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
      this.route = `${variables.CLAIMS_ROUTE}/${this.publicId}`;
    } else if (this.userType.role == variables.BROKER_USER_ROLE) {
      // Broker role claim information route
      this.route = `${variables.BROKER_CLIENTS_CLAIMS_ROUTE}/${this.publicId}`;
      this.deleteRoute = `${variables.BROKER_CLIENTS_CLAIMS_ROUTE}`;
    } else if (this.userType.role == variables.UNDERWRITER_USER_ROLE) {
      // Underwriter role claim information route
      this.route = `${variables.UNDERWRITERS_CLIENT_CLAIMS_ROUTE}/${this.publicId}`;
    } else if (this.userType.role == variables.COOPERATIVE_ADMIN_USER_ROLE) {
      // Cooperative role claim information route
      this.route = `${variables.COOPERATIVE_CLAIMS_ROUTE}/${this.publicId}`;
    } else if (
      this.userType.role == variables.ADMIN_USER_ROLE ||
      this.userType.role == variables.SUPER_ADMIN_USER_ROLE
    ) {
      // Super Admin & Admin role claim information route
      this.route = `${variables.SUPERADMIN_CLIENT_CLAIMS_ROUTE}/${this.publicId}`;
      this.deleteRoute = `${variables.BROKER_CLIENTS_CLAIMS_ROUTE}`;
    }

    // Get claim information
    this.getClaimInformation();
  },
  methods: {
    getClaimInformation() {
      const router = useRouter();
      //Fetch vehicle claim vehicle information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(this.route)
          .then(({ data }) => {
            //Assign data to variables
            this.claim = data.data;

            if (data.data.lossType === "Accident") {
              this.isAccidentClaim = true;
            }
            if (data.data.lossType === "Fire Damage") {
              this.isFireClaim = true;
            }
            if (data.data.lossType === "Third-Party Incident") {
              this.isThirdPartyClaim = true;
            }
            if (data.data.lossType === "Stolen(Theft)") {
              this.isStolenClaim = true;
            }
            if (data.data.lossType === "Vandalised") {
              this.isVandalisedClaim = true;
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    activateClaim(publicId) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(`${this.route}/activate`, publicId)
          .then(({ data }) => {
            variables.toastAlert(data.data, "success");
            this.getClaimInformation();
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/no-photo-available.png";
    },
    downloadClaimDocument(id, uniqueFileName) {
      const submitButton1 = document.getElementById(id);

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        // Delete cart item
        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.setResponseType("blob");
          ApiService.get(
            `${variables.CLAIMS_ROUTE}/${this.publicId}/document/${uniqueFileName}`
          )
            .then((response) => {
              submitButton1.removeAttribute("data-kt-indicator");
              // console.log(response.data);
              const blob = new Blob([response.data], {
                type: "application/octet-stream",
              });

              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = uniqueFileName;
              // link.download = `${fileName}.${extension}`;

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(function(error) {
              submitButton1.removeAttribute("data-kt-indicator");
              console.log(error);
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }
    },
    deleteClaimImage(publicId) {
      // Delete claim image
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.delete(`${variables.CLAIMS_ROUTE}/image/${publicId}`)
          .then(() => {
            this.getClaimInformation();
            variables.toastAlert("Image deleted successfully", "success");
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    deleteClaimDocument(publicId) {
      // Delete claim document
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.delete(`${variables.CLAIMS_ROUTE}/document/${publicId}`)
          .then(() => {
            this.getClaimInformation();
            variables.toastAlert("Document deleted successfully", "success");
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    verifyClaimImage(publicId) {
      // Veridy claim image
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(`/broker/claim-image/${publicId}/verify`)
          .then(() => {
            this.getClaimInformation();
            variables.toastAlert(
              "Claim image verified successfully",
              "success"
            );
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    verifyClaimDocument(publicId) {
      // Veridy claim document
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(`/broker/claim-document/${publicId}/verify`)
          .then(() => {
            this.getClaimInformation();
            variables.toastAlert(
              "Claim document verified successfully",
              "success"
            );
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    hasItems: function(content) {
      if (!content) {
        return false;
      }
      return content.length > 0;
    },
    printPage(el) {
      variables.printPage(el);
    },
  },
});
